// SPDX-FileCopyrightText: © 2023 Code Reckons

import $ from 'jquery';
import {Container} from 'golden-layout';
import {Chart, Plugin as ChartPlugin} from 'chart.js';

import {Pane} from './pane.js';
import {GprofState} from './gprof-view.interfaces.js';
import {PaneState} from './pane.interfaces.js';

import {ga} from '../analytics.js';
import {Hub} from '../hub.js';
import {CompilerInfo} from '../compiler.interfaces.js';
import {CompilationResult} from '../compilation/compilation.interfaces.js';

type State = GprofState & PaneState;

export class Gprof extends Pane<GprofState> {
    isCompilerSupported?: boolean;
    state: State;
    diagnostic?: string;

    pieButton: JQuery;
    sourcesButton: JQuery;
    panes: {pie: HTMLElement; sources: HTMLElement};
    diagnosticElem: HTMLElement;
    pieCanvas: HTMLCanvasElement;
    pieLegend: HTMLElement;
    pieChart: Chart<any> | null = null;

    constructor(hub: Hub, container: Container, state: State) {
        if (!(state as any).view) state.view = 'pie';
        super(hub, container, state);
        this.state = state;
        this.eventHub.emit('gprofViewOpened', this.compilerInfo.compilerId);
        this.eventHub.emit('requestCompilation', this.compilerInfo.editorId ?? 0, this.compilerInfo.treeId ?? 0);
        this.redraw();
    }

    override getInitialHTML(): string {
        return $('#gprof').html();
    }

    override getDefaultPaneName(): string {
        return 'Gprof Viewer';
    }

    override registerButtons(state: State): void {
        this.pieButton = this.domRoot.find('.pie-btn');
        this.pieButton.on('click', () => this.setView('pie'));
        this.sourcesButton = this.domRoot.find('.sources-btn');
        this.sourcesButton.on('click', () => this.setView('sources'));

        if (
            ![this.pieButton[0], this.sourcesButton[0]].some(b => {
                return b.classList.contains('active');
            })
        ) {
            const buttons = {pie: this.pieButton, sources: this.sourcesButton};
            const btn = buttons[state.view][0];
            btn.classList.add('active');
            btn.setAttribute('aria-selected', 'true');
        }
    }

    override registerDynamicElements(): void {
        this.panes = {
            pie: this.domRoot.find('.pie-pane')[0],
            sources: this.domRoot.find('.sources-pane')[0],
        };
        this.diagnosticElem = this.domRoot.find('.diagnostic')[0];
        this.pieCanvas = this.domRoot.find('.gprof-canvas')[0] as HTMLCanvasElement;
        this.pieLegend = this.domRoot.find('.gprof-legend')[0];
    }

    override registerOpeningAnalyticsEvent(): void {
        ga.proxy('send', {
            hitType: 'event',
            eventCategory: 'OpenViewPane',
            eventAction: 'GprofViewPane',
        });
    }

    override onCompiler(
        compilerId: number,
        compiler: CompilerInfo | null,
        options: string,
        editorId: number,
        treeId: number,
    ): void {
        if (compilerId !== this.compilerInfo.compilerId) return;
        this.compilerInfo.compilerName = compiler ? compiler.name : '';
        this.compilerInfo.editorId = editorId;
        this.compilerInfo.treeId = treeId;
        this.isCompilerSupported = compiler ? compiler.supportsGprofView : false;
        this.updateTitle();

        if (!this.isCompilerSupported) {
            this.diagnostic = '<Gprof is not supported for this compiler>';
            this.redraw();
        }
    }

    override onCompileResult(compilerId: number, compiler: CompilerInfo, result: CompilationResult): void {
        if (this.compilerInfo.compilerId !== compilerId) return;
        if (!result.execResult?.gprofOutput) return;
        const output = result.execResult.gprofOutput;
        if ('error' in output) {
            this.diagnostic = output.error;
            this.state.data = undefined;
        } else {
            this.diagnostic = undefined;
            this.state.data = output;
        }
        this.redraw();
    }

    setView(view: typeof this.state.view) {
        if (this.state.view !== view) {
            this.state.view = view;
            this.redraw();
        }
    }

    redraw() {
        this.panes.pie.classList.toggle('active', this.state.view === 'pie');
        this.panes.sources.classList.toggle('active', this.state.view === 'sources');
        this.diagnosticElem.textContent = this.diagnostic ?? null;

        if (this.state.view === 'pie' && this.state.data) {
            const flat = this.state.data.flat;
            const data = {
                labels: flat.map(row => row.name),
                datasets: [
                    {
                        label: 'Number of calls',
                        data: flat.map(row => row.calls),
                    },
                ],
            };

            if (this.pieChart) {
                this.pieChart.data.labels = data.labels;
                this.pieChart.data.datasets = data.datasets;
                this.pieChart.update();
            } else {
                const plugins = {
                    title: {
                        display: true,
                        text: 'Number of function calls',
                    },
                    legend: {
                        display: false,
                    },
                    gprofLegend: {
                        container: this.pieLegend,
                    },
                };

                this.pieChart = new Chart(this.pieCanvas, {
                    type: 'doughnut',
                    plugins: [legendPlugin],
                    options: {plugins},
                    data,
                });
            }
        }

        this.updateState();
    }

    override resize(): void {
        // throw new Error('Method not implemented.');
    }

    override getCurrentState(): GprofState & PaneState {
        const state = {
            id: this.compilerInfo.compilerId,
            compilerName: this.compilerInfo.compilerName,
            editorid: this.compilerInfo.editorId,
            treeid: this.compilerInfo.treeId,
            view: this.state.view,
        };
        this.paneRenaming.addState(state);
        return state;
    }

    override close(): void {
        this.eventHub.unsubscribe();
        this.eventHub.emit('gprofViewClosed', this.compilerInfo.compilerId);
    }
}

function installLegendList(container: HTMLElement): HTMLElement {
    let list = container.firstElementChild as HTMLElement | null;

    if (!list) {
        list = document.createElement('ul');
        list.style.margin = '0';
        list.style.marginTop = '20px';
        list.style.padding = '0';
        container.appendChild(list);
    }

    return list;
}

const legendPlugin: ChartPlugin = {
    id: 'gprofLegend',
    afterUpdate(chart, args, options) {
        const list = installLegendList(options.container);
        while (list.firstChild) {
            list.firstChild.remove();
        }

        const items = chart.options.plugins?.legend?.labels?.generateLabels?.(chart) ?? [];

        for (const [i, item] of items.entries()) {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.marginLeft = '10px';

            li.addEventListener('click', () => {
                chart.toggleDataVisibility(i);
                chart.update();
            });

            const box = document.createElement('span');
            box.style.background = (item.fillStyle ?? 'transparent') as string;
            box.style.borderColor = (item.strokeStyle ?? 'black') as string;
            box.style.borderWidth = item.lineWidth + 'px';
            box.style.display = 'inline-block';
            box.style.flexShrink = '0';
            box.style.height = '20px';
            box.style.marginRight = '10px';
            box.style.width = '20px';

            const text = document.createElement('p');
            text.style.color = (item.fontColor ?? 'currentColor') as string;
            text.style.margin = '0';
            text.style.padding = '0';
            text.style.textDecoration = item.hidden ? 'line-through' : '';

            const calls = chart.data.datasets[item.datasetIndex ?? 0].data[i];
            const s = calls !== 1 ? 's' : '';
            text.appendChild(document.createTextNode(`${item.text}: ${calls} call${s}`));

            li.append(box, text);
            list.append(li);
        }
    },
};
